import { useCallback, useEffect } from "react";
// import { setPianoConsents } from "components/piano/piano";
import { setConsentUserHash } from "lib/helpers/piano";
import {
  setMixpanelOnAccept,
  setMixpanelOnDecline,
} from "lib/mixpanel/mixpanel";

type CookiebotEvent =
  | "CookiebotOnConsentReady"
  | "CookiebotOnLoad"
  | "CookiebotOnAccept"
  | "CookiebotOnDecline"
  | "CookiebotOnDialogInit"
  | "CookiebotOnDialogDisplay"
  | "CookiebotOnTagsExecuted";

/**
 * Visit https://www.cookiebot.com/en/developer#h-event-handling
 * for info on callbacks
 */
export const useCookiebotCallback = (
  event: CookiebotEvent,
  fn: EventListenerOrEventListenerObject
) => {
  useEffect(() => {
    if (!window.Cookiebot) return;

    window.addEventListener(event, fn);

    return () => {
      window.removeEventListener(event, fn);
    };
  }, [event, fn]);
};

function handleDialogDisplay() {
  window.addEventListener("CookiebotOnAccept", setConsentUserHash);
  window.addEventListener("CookiebotOnDecline", setConsentUserHash);
}

/**
 * This set-up ensures that on every page load the Mixpanel preferences are updated,
 * but only when the user interacts with the cookie modal will it save the user hash
 * in Piano
 */
export const useCookiebot = () => {
  const handleCookiebot = useCallback(() => {
    //commenting out this and below due to issues with users being logged out.
    // window.addEventListener("CookiebotOnLoad", setPianoConsents);
    window.addEventListener("CookiebotOnAccept", setMixpanelOnAccept);
    window.addEventListener("CookiebotOnDecline", setMixpanelOnDecline);
    window.addEventListener("CookiebotOnDialogDisplay", handleDialogDisplay);
  }, []);

  const cleanUpCookiebot = useCallback(() => {
    window.removeEventListener("CookiebotOnAccept", setConsentUserHash);
    window.removeEventListener("CookiebotOnDecline", setConsentUserHash);
    window.removeEventListener("CookiebotOnAccept", setMixpanelOnAccept);
    window.removeEventListener("CookiebotOnDecline", setMixpanelOnDecline);
    // window.removeEventListener("CookiebotOnLoad", setPianoConsents);
    window.removeEventListener("CookiebotOnDialogDisplay", handleDialogDisplay);
  }, []);

  useEffect(() => {
    handleCookiebot();
    return () => {
      cleanUpCookiebot();
    };
  }, [handleCookiebot, cleanUpCookiebot]);
};
