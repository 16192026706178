import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { useCookie } from "react-use";
import { useUserData } from "lib/store/user";
import { getValueFromUserCookie } from "lib/userCookies";
import type { OnboardingFormattedRes } from "lib/types/onboarding";

const OnboardingModal = dynamic(
  () => import("components/onboarding/onboardingModal"),
  { ssr: false }
);

const OnboardingDialog = () => {
  const { pianoId, readerSubscriptionLevel, showOnboarding } = useUserData();
  const [onboardingData, setOnboardingData] =
    useState<OnboardingFormattedRes | null>(null);

  const [userCookie] = useCookie("__utp");

  const givenName = Boolean(userCookie)
    ? getValueFromUserCookie({
        userCookie: userCookie as string,
        key: "given_name",
      })
    : "";

  useEffect(() => {
    async function asyncFn() {
      const show = await showOnboarding();

      if (show) {
        const dato: OnboardingFormattedRes = await fetch(
          "/api/onboarding"
        ).then((res) => res.json());

        setOnboardingData(dato);
      }
    }
    asyncFn();
  }, [showOnboarding]);

  if (
    readerSubscriptionLevel === "unregistered" ||
    readerSubscriptionLevel === "member"
  )
    return null;
  return (
    <>
      {onboardingData ? (
        <OnboardingModal
          givenName={String(givenName)}
          readerSubscriptionLevel={readerSubscriptionLevel}
          data={onboardingData}
          pianoId={pianoId}
        />
      ) : null}
    </>
  );
};

export default OnboardingDialog;
