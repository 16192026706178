import { ReactElement } from "react";
import classNames from "classnames";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./lineAnimation.module.css";

const GridLinePath = ({ d }: { d: string }) => (
  <path
    d={d}
    stroke="#CBCBCB"
    strokeOpacity="0.8"
    strokeWidth="0.9375"
    strokeLinecap="round"
  />
);

const LetterPath = ({
  d,
  stroke = "#020231",
}: {
  d: string;
  stroke?: string;
}) => <path d={d} stroke={stroke} strokeWidth="3.75" strokeLinecap="round" />;

type GraphSVGDesktopProps = {
  lastItemLabel?: string | ReactElement;
};

const GraphSVGDesktop = ({
  lastItemLabel = "404 error",
}: GraphSVGDesktopProps) => (
  <svg
    className="hidden max-w-[100%] md:block"
    width="610"
    height="401"
    viewBox="0 0 610 401"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMin"
  >
    {/* Fun */}
    <g>
      <LetterPath d="M9.97174 40C9.65782 40 10.3046 53.4823 10.3046 55.3115" />
      <LetterPath d="M9.6387 40.333C13.6499 40.333 17.5999 40.9987 21.6216 40.9987" />
      <LetterPath d="M12.3016 49.3205C9.65641 48.9426 17.6345 48.9499 20.2902 48.6548" />
      <LetterPath d="M28.6113 41.6643C27.478 43.0808 27.5607 46.1875 27.5018 47.8036C27.4147 50.1913 27.9413 54.6827 31.2742 54.6827C37.0784 54.6827 37.6158 44.698 35.9342 40.9985" />
      <LetterPath d="M42.2583 52.9816C42.2583 50.1471 42.2243 47.2126 42.2583 44.3273C42.2692 43.3954 42.3536 40.6574 42.6096 41.5535C43.035 43.0422 43.9013 44.4327 44.5883 45.8067C45.202 47.0341 49.3654 56.1514 50.5797 50.9105C51.0261 48.9842 52.9381 41.6833 50.9126 40.333" />
      <LetterPath
        d="M3.23671 64.7152C2.55769 64.0361 3.48738 64.7153 4.17036 64.5715C9.34648 63.4818 14.5122 62.549 19.7551 61.7706C30.6625 60.1511 40.7552 59.9237 51.7145 60.8369"
        stroke="#DA145E"
      />
    </g>
    {/* Activity */}
    <g>
      <LetterPath d="M471.135 380.924C471.169 381.129 471.568 380.153 471.618 380.055C472.437 378.441 473.351 376.857 474.273 375.3C475.667 372.946 477.195 370.676 478.618 368.347C478.958 367.792 478.991 368.098 479.198 368.601C479.753 369.948 480.269 371.335 480.694 372.729C481.467 375.258 481.653 380.068 483.953 381.793" />
      <LetterPath d="M475.697 376.146C474.96 376.227 475.275 376.338 475.854 376.375C477.431 376.477 479.158 376.413 480.694 376.797" />
      <LetterPath d="M495.903 370.715C495.818 370.038 494.765 369.829 494.261 369.858C492.182 369.977 490.261 371.495 489.385 373.322C488.318 375.547 489.435 378.352 491.377 379.731C493.072 380.936 496.08 380.98 497.424 379.188" />
      <LetterPath d="M506.766 370.713C506.766 371.976 506.983 373.343 506.983 374.672C506.983 375.985 506.882 377.348 507.031 378.656C507.072 379.011 507.323 379.479 506.983 378.969" />
      <LetterPath d="M503.942 370.28C502.779 370.827 503.205 370.497 504.377 370.497C506.981 370.497 509.621 370.096 512.198 370.28" />
      <LetterPath d="M518.933 370.713C518.752 373.958 518.933 377.222 518.933 380.49" />
      <LetterPath d="M524.364 371.366C524.364 371.1 524.521 371.499 524.606 371.595C525.572 372.699 526.192 374.074 526.899 375.349C527.272 376.021 528.964 379.743 530.013 379.743C530.46 379.743 531.493 375.635 531.679 375.204C532.658 372.931 533.892 370.818 534.141 368.324" />
      <LetterPath d="M540.876 369.628C540.876 372.809 541.093 375.998 541.093 379.188" />
      <LetterPath d="M550.436 369.844C550.246 369.868 550.654 376.59 550.654 377.883" />
      <LetterPath d="M547.177 368.541C544.23 368.91 553.124 368.552 556.085 368.324" />
      <LetterPath d="M570.268 367.994C570.177 367.173 567.567 370.313 567.552 370.328C564.388 373.433 561.363 376.46 558.555 379.893" />
      <LetterPath d="M561.157 367.435C561.13 367.189 560.849 366.689 561.074 367.27C561.765 369.046 562.391 370.842 563.016 372.641" />
      <LetterPath
        d="M466 387.123C467.244 386.219 470.562 386.869 471.853 386.869C478.111 386.869 484.371 386.934 490.626 386.728C501.538 386.37 512.44 385.787 523.349 385.346C538.172 384.748 552.978 384.952 567.806 385.092"
        stroke="#DA145E"
      />
    </g>
    {/* BG Grid */}
    <GridLinePath d="M64.1464 239.091C64.3169 243.874 123.517 236.745 128.842 236.285C176.003 232.214 223.183 228.954 270.395 225.607C334.998 221.027 399.747 220.396 464.465 218.936" />
    <GridLinePath d="M62.277 173.055C66.9773 172.991 72.1353 171.76 76.7888 171.226C100.227 168.537 123.654 166.052 147.15 163.967C251.065 154.748 355.161 152.888 459.373 150.185" />
    <GridLinePath d="M66.3082 105.349C50.7194 103.92 97.6054 106.09 113.25 105.755C158.729 104.783 204.073 101.913 249.507 99.6985C320.49 96.2384 391.477 94.9285 462.511 93.2856" />
    <GridLinePath d="M66.9649 305.323C69.7535 305.323 71.5808 306.595 74.5917 306.388C99.4991 304.678 124.27 301.526 149.207 299.865C193.166 296.937 237.011 292.806 281.024 290.679C341.359 287.762 401.446 285.416 461.652 280.161" />
    <GridLinePath d="M104.465 350.474C101.923 350.182 102.758 333.997 102.605 331.747C101.035 308.675 99.2124 285.594 98.4381 262.479C97.2926 228.283 97.7323 194.298 98.4381 160.097C99.1345 126.354 99.1519 92.4927 95.0899 58.9111" />
    <GridLinePath d="M202.82 350.474C202.82 343.807 201.199 336.807 200.775 330.035C199.347 307.215 200.464 284.464 201.305 261.64C202.62 225.965 201.648 190.231 203.198 154.535C204.595 122.359 206.502 90.1525 207.59 57.9736" />
    <GridLinePath d="M299.16 350.474C298.743 350.417 299.16 335.136 299.16 333.819C299.16 327.279 298.374 320.796 298.554 314.231C298.924 300.771 300.098 287.334 300.587 273.876C301.883 238.207 301.097 202.532 302.406 166.869C303.079 148.526 304.535 130.123 305.936 111.814C307.315 93.8063 310.249 76.0395 310.715 57.9736" />
    <GridLinePath d="M401.122 350.474C399.408 350.26 399.821 327.393 399.821 325.299C399.821 303.181 404.051 281.076 404.879 258.937C406.174 224.28 409.405 189.744 410.37 155.065C410.889 136.41 410.873 117.786 412.176 99.1619C413.159 85.1133 414.777 71.1289 414.777 57.0361" />

    {/* Arrow axes */}
    {/* Y axis*/}
    <path
      d="M66.0475 395.246C69.9391 403.937 66.0092 391.604 65.4094 387.932C63.1001 373.794 63.605 359.658 63.8143 345.378C65.3824 238.34 59.9373 131.194 57.4337 24.2236"
      stroke="#020231"
      strokeWidth="4.6875"
      strokeLinecap="round"
    />
    {/* X axis */}
    <path
      d="M2.58986 350.61C78.2261 352.941 216.771 350.4 292.575 350.61C379.931 350.852 508.293 351.555 595.09 355.162"
      stroke="#020231"
      strokeWidth="4.6875"
      strokeLinecap="round"
    />
    {/* X Arrow */}
    <path
      d="M577.746 378.573C581.045 375.934 585.351 374.142 588.828 371.414C594.966 366.596 600.778 361.383 606.872 356.508"
      stroke="#020231"
      strokeWidth="4.6875"
      strokeLinecap="round"
    />
    <path
      d="M605.989 356.508C605.691 353.823 599.502 348.628 597.948 346.897C592.273 340.573 585.844 333.611 577.746 330.912"
      stroke="#020231"
      strokeWidth="4.6875"
      strokeLinecap="round"
    />
    {/* Y Arrow */}
    <path
      d="M60.3519 9.64531C59.7319 9.56782 58.0149 5.80771 60.548 9.15498C64.6704 14.6024 69.5852 19.436 73.8851 24.7476C74.4033 25.3877 77.1213 29.4313 77.1213 29.0626"
      stroke="#020231"
      strokeWidth="4.6875"
      strokeLinecap="round"
    />
    <path
      d="M58.5864 6.11426C55.0603 9.64039 43.0475 31.4424 40.0518 29.9445"
      stroke="#020231"
      strokeWidth="4.6875"
      strokeLinecap="round"
    />
    <text x="82" y="374" fill="#020231" className="text-[13px]">
      browsing
    </text>
    <text x="82" y="394" fill="#DA145E" className="text-[13px] font-bold">
      sifted.eu
    </text>
    <a href="https://sifted.eu/">
      <rect
        fill="transparent"
        fillOpacity="0"
        x="81"
        y="381"
        width="54.7698"
        height="16.8"
      />
    </a>
    <text x="184.5" y="374" fill="#020231" className="text-[13px]">
      reading
    </text>
    <text x="183" y="394" fill="#DA145E" className="text-[13px] font-bold">
      articles
    </text>
    <a href="https://sifted.eu/latest/">
      <rect
        fill="transparent"
        fillOpacity="0"
        x="183"
        y="381"
        width="47.3075"
        height="16.8"
      />
    </a>
    <text x="275.5" y="374" fill="#020231" className="text-[13px]">
      exploring
    </text>
    <text x="275" y="394" fill="#DA145E" className="text-[13px] font-bold">
      Briefings
    </text>
    <a href="https://sifted.eu/pro/briefings">
      <rect
        fill="transparent"
        fillOpacity="0"
        x="275"
        y="381"
        width="56.6642"
        height="16.8"
      />
    </a>
    <text x="369" y="374" fill="#020231" className="text-[13px]">
      getting this
    </text>
    <text x="373" y="394" fill="#020231" className="text-[13px] font-bold">
      {lastItemLabel}
    </text>
    {/* Y axis faces */}
    <path
      d="M15.577 109.271C15.577 112.405 18.1485 115.377 19.7372 117.907C21.6922 121.021 24.5694 122.046 28.1629 121.567C34.3174 120.746 38.1652 114.597 38.3263 108.797C38.3977 106.226 38.491 107.375 35.9566 107.375C33.2302 107.375 30.5946 107.849 27.8996 107.849C25.9074 107.849 22.6854 107.96 20.7641 108.56C18.7896 109.177 16.3341 108.892 14.6292 109.745"
      stroke="#020231"
      strokeWidth="3.6513"
      strokeLinecap="round"
    />
    <path
      d="M20.3164 96.9482C20.0799 97.1585 19.2983 97.4222 19.8425 97.4222"
      stroke="#020231"
      strokeWidth="7.3026"
      strokeLinecap="round"
    />
    <path
      d="M33.1129 96C32.8112 96 32.808 96.1359 32.6389 96.4739"
      stroke="#020231"
      strokeWidth="7.3026"
      strokeLinecap="round"
    />
    <path
      d="M18.5888 164.694C18.0138 165.351 18.5888 164.756 18.5888 165.205"
      stroke="#020231"
      strokeWidth="7.87515"
      strokeLinecap="round"
    />
    <path
      d="M32.3886 163.161C32.1619 163.416 31.8775 164.259 31.8775 163.672"
      stroke="#020231"
      strokeWidth="7.87515"
      strokeLinecap="round"
    />
    <path
      d="M14.5 179.005C14.5 178.339 15.1383 180.502 15.2951 180.794C16.4071 182.859 19.0746 184.036 21.1444 184.882C24.9962 186.458 27.2179 186.821 31.2528 186.671C35.4669 186.515 39.9633 180.354 38.0107 176.449"
      stroke="#020231"
      strokeWidth="3.93757"
      strokeLinecap="round"
    />
    <path
      d="M15.1117 245.904H37.8691"
      stroke="#020231"
      strokeWidth="4.1744"
      strokeLinecap="round"
    />
    <path
      d="M21.0719 229.107C20.5003 229.393 20.5994 229.52 21.0719 229.107"
      stroke="#020231"
      strokeWidth="8.3488"
      strokeLinecap="round"
    />
    <path
      d="M34.0761 228.566C34.0761 229.023 35.1171 227.51 33.5343 228.566"
      stroke="#020231"
      strokeWidth="8.3488"
      strokeLinecap="round"
    />
    <path
      d="M15.7039 309.75C16.3265 304.147 22.2019 296.18 28.3499 297.297C30.7468 297.733 32.8567 300.259 34.3415 302.074C35.2827 303.224 35.9034 304.627 36.302 306.05C36.5732 307.019 38.1749 310.85 36.5781 309.253"
      stroke="#020231"
      strokeWidth="3.82896"
      strokeLinecap="round"
    />
    <path
      d="M20.1769 288.379V287.882"
      stroke="#020231"
      strokeWidth="7.65793"
      strokeLinecap="round"
    />
    <path
      d="M33.0991 287.882C32.2995 287.882 33.8986 287.385 33.0991 287.385"
      stroke="#020231"
      strokeWidth="7.65793"
      strokeLinecap="round"
    />
    {/* Line on graph */}
    <path
      className={classNames(styles.line, styles["line-desktop"])}
      d="m68 235h27.3867l1.3867-.692c6.179-3.37 11.6124-8.22 17.4104-12.211 9.6617-6.649 19.379-13.006 29.3513-19.182 8.1036-5.019 16.5727-9.767 24.9212-14.367 1.826-1.006 4.513-3.814 6.664-3.814 2.151 0-.951.126 6.433 4.314 7.384 4.188 5.998 6.05 12.808 10.817 6.81 4.767 6.707 6.846 7 4.5.293-2.346 1.323-3.437 2.167-5.478 3.278-7.92 7.991-15.922 11.994-23.4846 7.294-13.7767 15.415-26.7495 24.06-39.7159 1.547-2.3215 3.825 1.295 6.463 3.8423 2.639 2.5474 5.319 4.5721 8.403 6.4996 3.084 1.9276 2.33.0001 4.913-3.6634 2-2.8362 2.946-4.1953 5.429-6.6785.822-.8215 1.071-.8215 2.011.4309 4.679 2.8595 7.975 10.4423 10.773 14.9384 6.609 10.6219 9.921 20.6811 17.287 30.8092 1.5 2.062 2.176 2.5 3.5 0 3.631-6.8595 5.782-12.0859 7-20 .077-.5 1.237.3928 1.5 2.5.263 2.1071 1.937 3.7228 3.175 5.5794 1.238 1.8566 1.45 4.2984 1.825.9206.375-3.3779 2.016-5.044 3.346-8.1742 7.645-17.9875 12.731-37.8523 17.452-56.737.272-1.0888 1.139.4112 2.693 3.5194 2.759 5.5178 2.625 7.0362 6.509 11.8918.5 1.5 3.5 5.5 3.223 3.6924 2.237-2.9832 4.444-6.0585 6.463-9.1928 7.682-11.9234 14.152-21.4013 22.314-32.9997 2-3.5 1.5-1.4999 3 0 .5-.5 1.685 1.883 1.763 2.0449 2.715 5.6484 5.173 11.4246 7.684 17.1648 0 1.2904 2.053 2.7904 3.231 1.9403 1.322-1.1499 2.05-1.6384 2.873-2.0827 6.884-3.7148 13.495-8.0922 19.75-12.7838 1.199-1.2834 2.165.6127 1.199 1.7166-.966 1.1039.505 3.6465.597 4.8196 1.056 13.4721-.431 27.1062-.431 40.5777 0 30.1711 3.789 60.4307 4.74 90.5997 1.046 33.175 9.151 86.23 10.256 119.395"
      stroke="#3350DB"
      strokeWidth="6.5625"
      strokeLinecap="round"
    />
  </svg>
);

export default GraphSVGDesktop;
