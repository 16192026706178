import { datadogLogs } from "@datadog/browser-logs";
import { DATADOG_API_TOKEN } from "lib/constants";

export const initDatadogLogger = () => {
  datadogLogs.init({
    clientToken: DATADOG_API_TOKEN,
    site: "datadoghq.eu",
    service: "content-ui",
    env: process.env.NODE_ENV,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    beforeSend: () => {
      if (typeof window?.Cookiebot?.consent?.statistics === 'boolean' && !window.Cookiebot.consent.statistics) {
        return false
      }
    },
  });
};
