export const TitleSVGDesktop = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1039 136">
    <path
      stroke="#DA145E"
      strokeLinecap="round"
      strokeWidth="10"
      d="M7.188 131c.217-1.835 2.167-4.595 3.317-5.895 4.876-5.511 7.653-12.279 10.825-18.973C38.815 69.238 59.825 37.128 84.188 5"
    />
    <path
      stroke="#DA145E"
      strokeLinecap="round"
      strokeWidth="10"
      d="M83.188 5c3.135 4.41 8.626 11.226 10.886 13.23 34.913 30.958 55.047 72.338 78.114 112.77M5.188 131h167"
    />
    <path
      stroke="#020231"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M239.123 55.717c-3.946-1.857-7.878-2.901-12.294-2.837-12.261.177-15.426 10.766-7.88 19.648 5.098 6.002 12.179 6.298 19.491 6.83 5.511.401 8.379 2.349 6.147 8.301-5.151 13.736-29.528 15.824-41.399 13.45M305.169 89.27c0-2.362.654-4.66.912-7.01.648-5.902.265-11.951 1.767-17.726.285-1.097 1.795-11.299 3.362-11.455.9-.09 3.151 11.829 3.733 12.994 4.074 8.148 10.001-10.762 11.998-12.396 2.537-2.076 3.043 6.879 3.106 7.466.992 9.256 2.019 18.41 3.334 27.614M343.127 55.928c1.701.8 2.608.23 4.331 0 6.329-.844 12.451.063 18.752.513M342.101 56.441c.317 2.54 1.74 5.323 1.937 8.207.737 10.754.967 21.747 3.192 32.316"
    />
    <path
      stroke="#020231"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M346.718 95.938c1.238 1.238 3.065.586 4.759.456 5.494-.423 10.944-2.202 16.272-3.534M348.77 79.01c1.246-1.107 2.766-.97 4.331-1.14 5.871-.634 11.474-1.528 17.212-2.963M392.37 58.493c-.6.675-.405 1.075-.257 1.938.478 2.769.271 5.698.257 8.492-.044 8.663-1.744 17.527.513 25.99M376.981 58.493c1.18-1.048 4.033-.822 5.472-1.14 9.321-2.062 18.343-2.689 27.87-2.45M419.556 55.928c-1.709 13.674-2.504 29.087-1.539 43.088M441.613 55.928c-1.35.169-1.262 7.877-1.311 8.52-.804 10.56-1.254 21.944-1.254 32.003"
    />
    <path
      stroke="#020231"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M419.043 78.498c-1.647 0 3.137-1.01 4.731-1.425 4.551-1.185 9.586-.627 14.248-.627M457.514 54.902c-.774.29-.514 1.716-.484 2.337.229 4.812.466 9.599.484 14.42.028 7.58 0 15.16 0 22.74M469.312 94.4c.377-3.4 1.241-6.676 1.424-10.26.298-5.803-.015-11.61.399-17.411.245-3.432.741-6.844.741-10.288M472.39 55.415c-.686.098.305.809.513 1.026 2.049 2.142 3.547 4.649 4.958 7.238 4.764 8.743 8.959 18.56 15.56 26.104M493.933 91.322c0-11.983-.513-23.926-.513-35.907M534.456 61.058c-3.188-3.587-7.631-4.75-12.425-3.99-5.902.937-8.57 7.444-10.373 12.51-2.607 7.325-2.845 16.46 3.42 22.028 4.503 4.003 10.847 3.878 15.986 1.226 3.074-1.587 4.471-5.262 1.226-7.666-.758-.561-4.254-2.052-2.422-2.052 2.882 0 5.768-.513 8.691-.513M277.672 62.049c-.143-1.15-4.139-.59-5.082-.59-5.313 0-9.392 4.898-11.412 9.443-3.097 6.968-1.854 12.573 4.394 16.822 4.816 3.275 9.956 4.884 15.609 2.623 6.798-2.719 12.321-11.193 8.886-18.264-2.022-4.163-2.504-6.564-7.083-8.854-5.207-2.603-8.668-2.16-14.165-.59M572.997 60.55c-.211-.27 2.791 4.18 3.477 6.163 2.053 5.935 3.939 11.928 6 17.86 1.107 3.184 3.004 6.65 3.433 10.036.134 1.056 2.897-4.809 3.369-5.861 4.953-11.026 12.789-20.037 18.717-30.451"
    />
    <path
      stroke="#020231"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M593.259 57.503c.004-.074 2.607 3.89 3.02 4.891 3.85 9.337 4.539 20.298 9.897 29.037 2.669 4.355 3.683-5.233 4.41-6.888 3.896-8.867 10.155-16.396 13.91-25.357M637 57.495c1.929-.784 4.82-.885 6.874-.812 7.382.263 14.176.912 21.543-.022M634.611 57.957c.667-12.383-2.512 24.684-3.179 37.068"
    />
    <path
      stroke="#020231"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M631.464 94.435c.192 2.721 23.052.45 25.407.187M635.299 78.091c7.285.393 14.54.8 21.839.586M668.689 94.667c.216-4.01 1.533-8.037 1.868-12.115.78-9.499 2.847-18.881 3.859-28.362"
    />
    <path
      stroke="#020231"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M674.322 55.959c-.408-.52 2.492 5.043 2.963 6.563 3.457 11.158 5.548 22.79 9.645 33.719M687.551 95.683c.125-2.33 1.306-4.508 1.876-6.795 2.762-11.072 5.789-22.2 7.387-33.491M706.244 55.905c.222-1.233 5.964.328 6.745.363 7.728.353 15.085-1.407 22.787.045M718.031 56.54c-.728 13.512-3.335 27.098-5.138 40.508"
    />
    <path
      stroke="#DA145E"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M858.076 52.49c-.198 2.686.02 5.224.04 7.917.065 8.802-.049 17.626-.168 26.426-.079 5.878-.843 12.01.29 17.81"
    />
    <path
      stroke="#DA145E"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M857.757 56.814c.12-1.627 1.391-1.61 2.881-2.01 7.994-2.15 18.384-5.403 25.677.157 7.285 5.555.43 15.103-4.183 19.654-4.058 4.003-8.407 7.003-14.312 7.187-2.52.079-4.979-.377-7.498-.068"
    />
    <path
      stroke="#DA145E"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M858.948 81.426c.479-2.405 10.479 2.25 11.392 2.647 9.662 4.186 18.466 10.755 27.307 16.459M925.019 58.557c-2.457-2.538-8.875 2.933-10.51 4.489-8.426 8.018-15.369 27.236-2.574 34.878 11.295 6.745 27.32-2.968 29.851-14.805 1.474-6.892.312-13.572-4.084-19.09-2.178-2.735-4.948-5.258-8.434-6.13-4.749-1.19-7.599 1.336-11.694 3.024M950.798 100.443c.396-5.364 2.964-10.922 4.054-16.215 1.457-7.08 2.155-14.319 3.719-21.36.085-.385 2.191-7.039 1.019-4.137"
    />
    <path
      stroke="#DA145E"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M959.946 58.005c.01-.131 2.756 4.17 3.048 4.715 6.413 11.95 11.014 25.136 15.875 37.79M980.717 102.651c.206-2.79 1.181-5.506 1.725-8.273 1.528-7.778 3.327-15.352 5.594-22.967 1.339-4.496 3.707-9.471 4.045-14.046M1027.39 64.045c-.92-.62-1.72-1.597-2.78-2.003-4.56-1.738-12.34 2.043-15.94 4.268-10.508 6.5-15.112 21.023-8.985 32.05 4.025 7.243 15.315 7.821 22.475 5.358 4.07-1.398 8.46-6.584 7.37-11.241-1.06-4.563-6.39-5.44-10.3-5.728-2.11-.156 3.31 1.093 4.37.983 3.22-.331 6.35-1.2 9.56-1.567 2.48-.282 2.43 0 .52 1.174M772.015 56.83c-1.48-.11.739 1.014 1.245 1.608 4.978 5.834 9.781 12.489 13.802 19.01 5.034 8.162 9.839 16.786 14.364 25.254 1.232 2.305.167 2.361.314.368.375-5.082 2.14-9.717 3.8-14.54 2.931-8.512 7.473-16.301 11.346-24.391 1.729-3.613 5.367-8.861 3.372-12.435"
    />
    <path
      stroke="#DA145E"
      strokeLinecap="round"
      strokeWidth="6.5"
      d="M796.877 52.931c.16 4.178 4.535 8.744 6.557 12.122 6.634 11.085 11.619 23.049 18.163 34.12 2.835 4.797 7.339-12.466 8.002-14.078 3.411-8.291 5.801-16.989 9.38-25.2 1.298-2.978 2.9-5.323 3.14-8.583"
    />
    <path
      stroke="#DA145E"
      strokeLinecap="round"
      strokeWidth="10"
      d="M85.429 89.959c-.321-1.043-.352-1.98-.352-3.052 0-3.068-.307-5.96-.9-8.978-1.631-8.303-3.697-16.607-5.086-24.942M87.189 91.015c0-1.034.346-1.772.704-2.738 1.147-3.104 1.082-6.933 1.76-10.172 1.768-8.447 3.8-16.867 6.338-25.118M80.85 51.931c-.45-.514 1.668-.627 2.074-.704 4.438-.838 8.65-1.193 13.067-.352M89.302 54.748c-.97 0-1.14.938-1.33 1.76-.961 4.131-1.396 8.47-1.84 12.676"
    />
    <path
      stroke="#DA145E"
      strokeLinecap="round"
      strokeWidth="10"
      d="M87.54 56.86c-1.02 4.595-1.056 9.058-1.056 13.733M83.316 110.029c1.438 1.439 3.504 1.178 4.343-.88 1.771-4.349-5.66-6.189-5.595-.098.02 1.937 1.95 4.856 4.225 4.343 2.42-.545 3.332-4.277 1.898-6.143-4.012-5.215-9.354 3.722-3.11 5.595"
    />
  </svg>
);

export const TitleSVGMobile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 262 52">
      <path
        stroke="#DA145E"
        strokeLinecap="round"
        strokeWidth="3.75"
        d="M2.887 49.25c.081-.688.813-1.723 1.244-2.21 1.828-2.067 2.87-4.605 4.06-7.116C14.746 26.09 22.625 14.048 31.76 2"
      />
      <path
        stroke="#DA145E"
        strokeLinecap="round"
        strokeWidth="3.75"
        d="M31.387 2c1.175 1.654 3.235 4.21 4.082 4.961 13.093 11.61 20.643 27.127 29.293 42.289M2.137 49.25h62.625M32.227 33.86c-.12-.392-.132-.743-.132-1.145 0-1.15-.115-2.235-.337-3.367-.612-3.113-1.386-6.227-1.907-9.353M32.887 34.256c0-.388.13-.665.264-1.027.43-1.164.406-2.6.66-3.815.663-3.167 1.425-6.325 2.377-9.419M30.51 19.6c-.168-.194.626-.236.778-.265 1.665-.314 3.244-.448 4.9-.132M33.68 20.655c-.364 0-.428.352-.5.66-.36 1.55-.523 3.176-.689 4.754"
      />
      <path
        stroke="#DA145E"
        strokeLinecap="round"
        strokeWidth="3.75"
        d="M33.02 21.448c-.384 1.722-.397 3.396-.397 5.15M31.434 41.386c.54.54 1.315.442 1.629-.33.664-1.63-2.122-2.32-2.098-.037.008.727.731 1.821 1.585 1.629.907-.205 1.25-1.604.711-2.304-1.504-1.955-3.508 1.396-1.166 2.099"
      />
      <path
        stroke="#020231"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M73.612 4.065c-1.48-.696-2.953-1.088-4.61-1.064-4.598.067-5.784 4.037-2.955 7.368 1.912 2.25 4.567 2.362 7.31 2.562 2.066.15 3.142.88 2.305 3.112-1.932 5.151-11.073 5.934-15.525 5.044M98.38 16.648c0-.886.245-1.748.342-2.63.243-2.213.1-4.48.662-6.646.107-.412.674-4.237 1.261-4.296.338-.034 1.182 4.436 1.4 4.873 1.528 3.055 3.751-4.036 4.499-4.649.952-.778 1.141 2.58 1.165 2.8.372 3.47.757 6.904 1.25 10.355M112.614 4.144c.638.3.978.086 1.625 0 2.373-.316 4.669.024 7.031.193M112.229 4.337c.12.952.653 1.996.727 3.077.276 4.033.363 8.155 1.197 12.119"
      />
      <path
        stroke="#020231"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M113.961 19.148c.464.464 1.149.22 1.785.171 2.06-.159 4.104-.826 6.101-1.325M114.73 12.8c.468-.415 1.038-.364 1.625-.427 2.202-.238 4.303-.574 6.454-1.111M131.081 5.106c-.225.253-.152.403-.097.727.179 1.038.102 2.137.097 3.184-.017 3.249-.654 6.573.192 9.746M125.31 5.106c.442-.393 1.512-.308 2.051-.427 3.496-.774 6.879-1.009 10.452-.92M141.275 4.145c-.641 5.127-.939 10.907-.577 16.157M149.546 4.145c-.506.063-.473 2.953-.492 3.195-.301 3.96-.47 8.228-.47 12"
      />
      <path
        stroke="#020231"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M141.083 12.608c-.618 0 1.176-.379 1.774-.534 1.706-.445 3.595-.235 5.343-.235M155.509 3.76c-.29.109-.193.643-.182.876.086 1.804.175 3.6.182 5.407.01 2.843 0 5.686 0 8.528M159.934 18.57c.141-1.274.465-2.502.534-3.846.112-2.176-.006-4.354.15-6.53.091-1.286.277-2.566.277-3.857M161.087 3.952c-.257.037.115.303.193.385.768.803 1.33 1.743 1.859 2.714 1.787 3.279 3.36 6.96 5.835 9.789M169.166 17.417c0-4.493-.192-8.972-.192-13.465M184.362 6.068c-1.196-1.345-2.862-1.781-4.659-1.496-2.214.351-3.214 2.792-3.89 4.691-.978 2.747-1.067 6.172 1.282 8.26 1.689 1.502 4.068 1.455 5.995.46 1.153-.595 1.677-1.973.46-2.874-.285-.21-1.596-.77-.909-.77 1.081 0 2.163-.192 3.26-.192M88.068 6.44c-.053-.431-1.552-.222-1.906-.222-1.992 0-3.521 1.838-4.279 3.542-1.161 2.613-.695 4.715 1.648 6.308 1.806 1.228 3.733 1.832 5.853.984 2.55-1.02 4.62-4.198 3.332-6.85-.758-1.56-.938-2.461-2.656-3.32-1.952-.976-3.25-.81-5.312-.22M198.815 5.877c-.079-.1 1.047 1.568 1.304 2.312.77 2.226 1.477 4.473 2.25 6.697.415 1.194 1.127 2.494 1.288 3.764.05.396 1.086-1.803 1.263-2.198 1.857-4.135 4.796-7.514 7.019-11.42"
      />
      <path
        stroke="#020231"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M206.413 4.735c.002-.028.978 1.459 1.133 1.834 1.443 3.501 1.702 7.612 3.711 10.889 1.001 1.633 1.381-1.963 1.654-2.583 1.461-3.325 3.808-6.149 5.216-9.51M222.817 4.732c.723-.294 1.807-.332 2.577-.305 2.769.099 5.316.342 8.079-.008M221.921 4.905c.25-4.644-.942 9.257-1.192 13.9"
      />
      <path
        stroke="#020231"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M220.741 18.585c.072 1.02 8.644.168 9.527.07M222.179 12.455c2.731.147 5.452.3 8.189.22M234.7 18.672c.081-1.505.575-3.014.7-4.544.293-3.562 1.068-7.08 1.448-10.635"
      />
      <path
        stroke="#020231"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M236.812 4.156c-.153-.195.935 1.89 1.112 2.46 1.296 4.185 2.08 8.547 3.616 12.646M241.773 19.052c.047-.874.49-1.69.704-2.548 1.036-4.152 2.171-8.325 2.77-12.559M248.783 4.136c.083-.463 2.237.122 2.529.136 2.898.132 5.657-.528 8.546.017M253.203 4.374c-.273 5.067-1.251 10.162-1.927 15.19"
      />
      <path
        stroke="#DA145E"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M100.845 30.684c-.075 1.007.007 1.959.015 2.969.024 3.3-.019 6.61-.063 9.91-.03 2.204-.316 4.503.109 6.678"
      />
      <path
        stroke="#DA145E"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M100.726 32.305c.045-.61.521-.603 1.08-.753 2.998-.807 6.894-2.027 9.629.058 2.732 2.084.161 5.664-1.569 7.37-1.522 1.502-3.153 2.627-5.367 2.696-.945.03-1.867-.142-2.812-.026"
      />
      <path
        stroke="#DA145E"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M101.172 41.535c.18-.902 3.93.844 4.272.992 3.624 1.57 6.925 4.033 10.24 6.172M125.948 32.959c-.921-.952-3.328 1.1-3.941 1.683-3.16 3.007-5.764 10.214-.965 13.08 4.235 2.529 10.245-1.113 11.194-5.552.553-2.585.117-5.09-1.532-7.16-.816-1.025-1.855-1.97-3.162-2.298-1.781-.446-2.85.501-4.386 1.134M135.616 48.666c.148-2.011 1.111-4.096 1.52-6.08.546-2.655.808-5.37 1.395-8.01.032-.145.821-2.64.382-1.552"
      />
      <path
        stroke="#DA145E"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M139.046 32.752c.003-.05 1.033 1.564 1.143 1.768 2.405 4.48 4.13 9.426 5.953 14.171M146.835 49.494c.077-1.046.443-2.065.647-3.102.573-2.917 1.247-5.757 2.098-8.613.502-1.686 1.39-3.552 1.516-5.267M164.338 35.017c-.347-.232-.645-.599-1.044-.75-1.71-.653-4.628.765-5.976 1.6-3.941 2.437-5.668 7.883-3.37 12.018 1.509 2.716 5.744 2.933 8.428 2.01 1.525-.525 3.171-2.47 2.765-4.216-.398-1.71-2.399-2.04-3.865-2.148-.788-.058 1.241.41 1.641.369 1.206-.125 2.381-.45 3.586-.588.929-.106.91 0 .193.44M68.572 32.311c-.555-.04.277.38.467.603a58.7 58.7 0 0 1 5.175 7.129c1.888 3.06 3.69 6.295 5.387 9.47.462.865.062.886.118.138.14-1.906.802-3.644 1.425-5.452 1.099-3.192 2.802-6.113 4.254-9.147.649-1.355 2.013-3.323 1.265-4.663"
      />
      <path
        stroke="#DA145E"
        strokeLinecap="round"
        strokeWidth="2.438"
        d="M77.896 30.85c.06 1.566 1.7 3.278 2.458 4.545 2.488 4.157 4.358 8.643 6.811 12.795 1.064 1.799 2.753-4.675 3.001-5.28 1.28-3.109 2.176-6.37 3.518-9.45.487-1.116 1.087-1.995 1.178-3.218"
      />
    </svg>
  );
};
