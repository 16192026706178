import { shallow } from "zustand/shallow";

import { useStore } from "./storeProvider";
import type { StoreState } from "./index";

type UseUserProps = Pick<StoreState, "user" | "setUser">;
type UseUserDataProps = StoreState["user"] & {
  /**
   * All registered users
   */
  isAuthorisedUser: boolean;
  /**
   * Registered pro users
   */
  isProUser: boolean;
  /**
   * Non pro registered user
   */
  isNonProUser: boolean;
};

export const useUser = (): UseUserProps => {
  const { user, setUser } = useStore(
    (store) => ({
      user: store.user,
      setUser: store.setUser,
    }),
    shallow
  );

  return { user, setUser };
};

export const useUserData = (): UseUserDataProps => {
  const { user } = useStore(
    (store) => ({
      user: store.user,
    }),
    shallow
  );

  return {
    ...user!,
    isNonProUser:
      user!.readerSubscriptionLevel === "registered" ||
      user!.readerSubscriptionLevel === "member" ||
      user!.readerSubscriptionLevel === "angel" ||
      user!.readerSubscriptionLevel === "startup",
    isProUser: user!.readerSubscriptionLevel === "pro",
    isAuthorisedUser:
      user!.readerSubscriptionLevel === "registered" ||
      user!.readerSubscriptionLevel === "member" ||
      user!.readerSubscriptionLevel === "angel" ||
      user!.readerSubscriptionLevel === "startup" ||
      user!.readerSubscriptionLevel === "pro",
  };
};
