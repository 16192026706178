import { PIANO_API_BASE } from "lib/constants";
import axios, { AxiosRequestConfig } from "axios";
import { MandatoryDataType, NlAndEventsDataType, OptionalDataType } from "membership-ui/login/types";

const config: AxiosRequestConfig = {
  baseURL: PIANO_API_BASE + "/api/v3", // `baseURL` will be prepended to `url` unless `url` is absolute.
};

type CustomFieldData = Partial<MandatoryDataType & OptionalDataType & NlAndEventsDataType & {
  showOnboarding?: { [key: string]: string };
  cookiebotUserHash?: string
}>

export async function updateCustomFields(cookie: string, customFieldData: CustomFieldData, userId: string, formId?: string) {
  const resp = await axios.post(
    `/api/subscriptionUtils/piano/updateCustomFields`,
    {
      cookie,
      customFields: customFieldData,
      userId,
      ...(formId && { formId })
    }
  );

  const data = await resp?.data;
  return data;
}

// The specified config will be merged with the instance config when used
export default axios.create(config);
