import React, { createContext, useContext } from "react";
import type { StoreFunction, StoreState } from "./index";

export const StoreContext = createContext<StoreFunction | null>(null);

type StoreProviderProps = {
  children: React.ReactNode;
  store: StoreFunction;
};

export const StoreProvider: React.FC<StoreProviderProps> = ({
  children,
  store,
}) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = (selector, eqFn) => {
  const store = useContext(StoreContext) as StoreFunction;
  return store<StoreState>(selector, eqFn);
};
