import jwtDecode from "jwt-decode";
import type { NextApiRequestCookies } from "next/dist/server/api-utils";

export type UserCookie = string;

export interface DecodedUserCookie {
  iss: string;
  sub: string;
  aud: string;
  login_timestamp: string;
  given_name: string;
  family_name: string;
  email: string;
  email_confirmation_required: boolean;
  exp: number;
  iat: number;
  jti: string;
  passwordType: string;
}

export const getUserCookie = (
  cookies: NextApiRequestCookies
): UserCookie | undefined => cookies?.__utp;

export const decodeUserCookie = (userCookie: UserCookie): DecodedUserCookie =>
  jwtDecode(userCookie);

export const getValueFromUserCookie = ({
  userCookie,
  key,
}: {
  userCookie: UserCookie;
  key: keyof DecodedUserCookie;
}) => {
  try {
    const decoded = decodeUserCookie(userCookie);
    return decoded[key] || null;
  } catch (e) {
    throw new Error(e);
  }
};
