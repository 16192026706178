import { useEffect } from "react";
import dynamic from "next/dynamic";
import type { AppProps } from "next/app";
import { DefaultSeo } from "next-seo";
import { LazyMotion, domMax } from "framer-motion";
import { Provider } from "react-wrap-balancer";

import useUserInfo from "lib/hooks/useUserInfo";
import ErrorBoundary from "components/errorBoundary/errorBoundary";
import OnboardingDialog from "components/onboarding/onboarding";

import "../styles/globals.css";
import "../styles/tailwind.css";

import {
  recklessNeueFontFamily,
  aktivGroteskFontFamily,
  sourceSansFontFamily,
} from "styles/fonts";

import { Mixpanel, setMixpanelOnPageLoad } from "lib/mixpanel/mixpanel";
import { MIXPANEL_TOKEN } from "lib/constants";

import { StoreProvider } from "lib/store/storeProvider";
import { useHydrate } from "lib/store";
import { initDatadogLogger } from "lib/datadog/logger";
import { useCookiebot } from "lib/hooks/useCookiebot";

const PianoHandlersNoSSR = dynamic(
  () => import("../lib/pianoCustomEventHandlers"),
  {
    ssr: false,
  }
);

const EmailVerificationBannerNoSSR = dynamic(
  () => import("content-ui/emailVerificationBanner/emailVerificationBanner"),
  {
    ssr: false,
  }
);

const BottomBannerNoSSR = dynamic(
  () => import("components/bottomBanner/bottomBanner"),
  {
    ssr: false,
  }
);

let mixpanel: Mixpanel | null = null;

function MyApp({ Component, pageProps }: AppProps) {
  const {
    pianoId,
    readerSubscriptionLevel,
    hasMissingInfo,
    showOnboarding,
    showVerification,
  } = useUserInfo();

  const store = useHydrate({
    ...pageProps.__initialState,
    user: {
      pianoId,
      readerSubscriptionLevel,
      hasMissingInfo,
      showOnboarding,
      showVerification,
    },
  });

  useEffect(() => {
    const ayncFn = async () => {
      mixpanel = new Mixpanel(MIXPANEL_TOKEN);
      const id = await pianoId;

      setMixpanelOnPageLoad();

      if (id && !window.location.pathname.includes("/articles/")) {
        let additionalTrackingProperties = {};
        if (
          pageProps &&
          pageProps.trackingData &&
          pageProps.trackingData.mixpanel
        ) {
          additionalTrackingProperties = pageProps.trackingData.mixpanel;
        }

        mixpanel.track("Page View", {
          ...additionalTrackingProperties,
          "Page Path": window.location.pathname,
          distinct_id: id,
        });
      }
    };

    ayncFn();

    if (process.env.NODE_ENV === "production") {
      initDatadogLogger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCookiebot();

  return (
    <>
      <DefaultSeo
        dangerouslySetAllPagesToNoFollow={process.env.STAGING === "true"}
        dangerouslySetAllPagesToNoIndex={process.env.STAGING === "true"}
      />
      <style jsx global>{`
        :root {
          --font-recklessNeueBold: ${recklessNeueFontFamily.style.fontFamily};
          --font-aktivGrotesk: ${aktivGroteskFontFamily.style.fontFamily};
          --font-sourceSans: ${sourceSansFontFamily.style.fontFamily};
        }
      `}</style>
      <PianoHandlersNoSSR />
      <ErrorBoundary fullSizeError={true}>
        <LazyMotion features={domMax} strict>
          <StoreProvider store={store}>
            <Provider>
              <div className={`font-serif`}>
                <OnboardingDialog />
                <Component {...pageProps} />
                <BottomBannerNoSSR
                  preview={pageProps.preview || pageProps.previewMode}
                  previewData={pageProps.previewData}
                />
                <EmailVerificationBannerNoSSR />
              </div>
            </Provider>
          </StoreProvider>
        </LazyMotion>
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
