import classNames from "classnames";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./lineAnimation.module.css";
import { ReactElement } from "react";

type GraphSVGMobileProps = {
  lastItemLabel?: string | ReactElement;
};

const GraphSVGMobile = ({
  lastItemLabel = "404 error",
}: GraphSVGMobileProps) => (
  <svg
    className="h-[min(401px,66vw)] w-[100%] md:hidden"
    width="303"
    height="238"
    viewBox="0 0 303 238"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMin"
  >
    <path
      d="M4.58485 59.2081C4.58485 60.9104 5.9814 62.5243 6.84418 63.8984C7.90589 65.5892 9.46849 66.1462 11.42 65.886C14.7625 65.4403 16.8522 62.1006 16.9397 58.9507C16.9784 57.5546 17.0291 58.1786 15.6527 58.1786C14.172 58.1786 12.7407 58.4359 11.277 58.4359C10.1951 58.4359 8.44528 58.496 7.40186 58.822C6.32956 59.1571 4.996 59.0025 4.07007 59.4655"
      stroke="#020231"
      strokeWidth="1.98297"
      strokeLinecap="round"
    />
    <path
      d="M7.15851 52.5156C7.03007 52.6298 6.60556 52.773 6.90112 52.773"
      stroke="#020231"
      strokeWidth="3.96593"
      strokeLinecap="round"
    />
    <path
      d="M14.1074 52C13.9441 52 13.9423 52.0736 13.8508 52.2566"
      stroke="#020231"
      strokeWidth="3.96593"
      strokeLinecap="round"
    />
    <path
      d="M6.22017 89.3076C5.90881 89.6635 6.22017 89.3411 6.22017 89.5843"
      stroke="#020231"
      strokeWidth="4.27688"
      strokeLinecap="round"
    />
    <path
      d="M13.7151 88.4746C13.5919 88.6131 13.4375 89.0709 13.4375 88.7522"
      stroke="#020231"
      strokeWidth="4.27688"
      strokeLinecap="round"
    />
    <path
      d="M4 97.0788C4 96.7171 4.34661 97.8921 4.43178 98.0503C5.0357 99.1719 6.48437 99.811 7.60844 100.271C9.70034 101.127 10.9069 101.324 13.0982 101.242C15.3868 101.158 17.8287 97.8117 16.7683 95.6909"
      stroke="#020231"
      strokeWidth="2.13844"
      strokeLinecap="round"
    />
    <path
      d="M4.33228 133.411H16.6915"
      stroke="#020231"
      strokeWidth="2.26706"
      strokeLinecap="round"
    />
    <path
      d="M7.56868 124.289C7.25916 124.443 7.31285 124.512 7.56868 124.289"
      stroke="#020231"
      strokeWidth="4.53411"
      strokeLinecap="round"
    />
    <path
      d="M14.6314 123.995C14.6314 124.243 15.1968 123.422 14.3372 123.995"
      stroke="#020231"
      strokeWidth="4.53411"
      strokeLinecap="round"
    />
    <path
      d="M4.65381 168.085C4.99193 165.042 8.18279 160.715 11.5217 161.322C12.8234 161.559 13.9692 162.931 14.7757 163.916C15.2868 164.541 15.6239 165.303 15.8403 166.076C15.9876 166.602 16.8575 168.682 15.9903 167.815"
      stroke="#020231"
      strokeWidth="2.07945"
      strokeLinecap="round"
    />
    <path
      d="M7.0835 156.478V156.208"
      stroke="#020231"
      strokeWidth="4.15891"
      strokeLinecap="round"
    />
    <path
      d="M14.1008 156.208C13.6679 156.208 14.5337 155.939 14.1008 155.939"
      stroke="#020231"
      strokeWidth="4.15891"
      strokeLinecap="round"
    />
    <text x="32" y="210" fill="#020231" className="text-[14px]">
      reading
    </text>
    <text x="31" y="231" fill="#DA145E" className="text-[14px] font-bold">
      articles
    </text>
    <a href="https://sifted.eu/latest/">
      <rect fill="transparent" x="31" y="217" width="50.4614" height="17.92" />
    </a>
    <text x="105" y="210" fill="#020231" className="text-[14px]">
      exploring
    </text>
    <text x="104" y="231" fill="#DA145E" className="text-[14px] font-bold">
      Briefings
    </text>
    <a href="https://sifted.eu/pro/briefings">
      <rect fill="transparent" x="104" y="217" width="60.4418" height="17.92" />
    </a>
    <text x="187.2" y="210" fill="#020231" className="text-[14px]">
      getting this
    </text>
    <text x="192.0" y="231" fill="#020231" className="text-[14px] font-bold">
      {lastItemLabel}
    </text>
    <path
      d="M23.6731 213.331C25.7866 218.051 23.6523 211.353 23.3266 209.359C22.0724 201.68 22.3467 194.004 22.4603 186.248C23.3119 128.117 20.3548 69.9279 18.9951 11.834"
      stroke="#020231"
      strokeWidth="2.54571"
      strokeLinecap="round"
    />
    <path
      d="M273.193 202.884C274.985 201.451 277.324 200.477 279.212 198.996C282.545 196.379 285.701 193.548 289.011 190.9"
      stroke="#020231"
      strokeWidth="2.54571"
      strokeLinecap="round"
    />
    <path
      d="M288.532 190.901C288.37 189.443 285.008 186.621 284.165 185.681C281.083 182.247 277.591 178.466 273.193 177"
      stroke="#020231"
      strokeWidth="2.54571"
      strokeLinecap="round"
    />
    <path
      d="M20.218 3.91715C19.8813 3.87506 18.9488 1.833 20.3245 3.65085C22.5633 6.60928 25.2324 9.23431 27.5677 12.119C27.8491 12.4666 29.3252 14.6626 29.3252 14.4624"
      stroke="#020231"
      strokeWidth="2.54571"
      strokeLinecap="round"
    />
    <path
      d="M19.2593 2C17.3443 3.91499 10.8203 15.7553 9.19336 14.9419"
      stroke="#020231"
      strokeWidth="2.54571"
      strokeLinecap="round"
    />
    <path
      d="M1.99994 190.652C37.1863 191.762 101.572 189.946 136.816 189.857C177.43 189.755 237.113 189.8 277.501 191.601"
      stroke="#020231"
      strokeWidth="2.54571"
      strokeLinecap="round"
    />
    {/* Line on graph */}
    <path
      className={classNames(styles.line, styles["line-mobile"])}
      d="M 25 106 c -0.492 0 0.017 -0.09 0.228 -0.253 c 1.887 -1.468 3.83 -2.84 5.699 -4.357 c 8.617 -6.993 16.617 -15.324 26.342 -20.82 c 1.301 -0.736 0.776 2.912 1.672 4.306 c 1.171 1.822 4.507 8.612 7.244 8.612 c 0.516 0 0.334 -0.979 0.557 -1.444 c 0.675 -1.411 1.603 -2.639 2.482 -3.926 c 2.18 -3.192 4.721 -6.394 7.295 -9.271 c 0.435 -0.486 0.055 0.091 0.025 0.33 c -0.232 1.855 0.548 3.982 1.089 5.699 c 2.49 7.892 5.983 15.417 9.372 22.948 c 1.505 -1.694 2.738 -5.11 3.748 -6.839 c 6.564 -11.237 12.857 -22.695 19.909 -33.637 c 2.458 -3.814 4.896 -7.901 7.801 -11.398 c 0.097 -0.117 2.055 -2.541 2.254 -1.646 c 1.689 7.601 3.762 14.772 6.864 22.061 c 1.701 3.998 3.505 7.736 5.826 11.398 c 0.684 1.08 1.659 3.297 3.292 3.116 c 1.431 -0.159 2.746 -2.599 3.42 -3.648 c 2.808 -4.367 5.246 -8.977 8.08 -13.348 c 8.918 -13.756 15.956 -28.704 25.43 -42.097 c 4.322 -6.109 8.501 -12.187 14.007 -17.299 c 0.099 -0.093 4.483 -3.69 4.483 -3.116 c 0 0.666 -1.621 0.5 -1.621 1.165 c -0.096 0 0.767 1.324 1.114 1.925 c 3.592 6.213 6.736 12.752 10.816 18.668 c 1.591 2.307 3.65 4.356 5.395 6.56 c 5.721 7.226 13.935 -2.932 18.591 -6.636 c 2.174 -1.73 1.545 -2.274 2.381 1.114 c 4.175 16.918 6.591 33.82 8.46 51.165 c 3.725 34.573 8.791 68.99 11.601 103.645"
      stroke="#3350DB"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);

export default GraphSVGMobile;
