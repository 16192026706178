import { useMemo } from "react";
import { create } from "zustand";

import type { UserInfo } from "lib/hooks/useUserInfo";
import type { Article } from "lib/types/article";
import type { HomepageProps } from "lib/types/homepage";
import type { SitewideOptions } from "lib/types/sitewideOption";

export type StoreStateData = {
  user: UserInfo;
  article: Article | null;
  homepage: HomepageProps | null;
  sitewideOption: SitewideOptions | null;
};

export type StoreStateDataInitial = Omit<StoreStateData, "user">;

export type StoreState = StoreStateData & {
  setUser: (user: UserInfo) => void;
  setArticle: (article: Article) => void;
  setHomepage: (homepage: HomepageProps) => void;
  setSitewideOption: (options: SitewideOptions) => void;
};

let store: StoreFunction | undefined;

export const INITIAL_STORE_STATE: StoreStateDataInitial = {
  article: null,
  homepage: null,
  sitewideOption: null,
};

export type StoreFunction = ReturnType<typeof initStore>;

function initStore(
  preloadedState: Pick<StoreStateData, "user"> & Partial<StoreStateDataInitial>
) {
  return create<StoreState>()((set) => ({
    ...INITIAL_STORE_STATE,
    ...(preloadedState || {}),
    setUser: (newVal) => {
      set({
        user: newVal,
      });
    },
    setArticle: (newArticle) => {
      set({
        article: newArticle,
      });
    },
    setHomepage: (newHomepage) => {
      set({
        homepage: newHomepage,
      });
    },
    setSitewideOption: (newSitewideOption) => {
      set({
        sitewideOption: newSitewideOption,
      });
    },
  }));
}

export const initializeStore = (
  preloadedState: StoreStateData
): StoreFunction => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Zustand state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useHydrate(initialState: Partial<StoreState>) {
  const state =
    typeof initialState === "string" ? JSON.parse(initialState) : initialState;
  return useMemo(() => initializeStore(state), [state]);
}
