import classNames from "classnames";

const LOGGED_OUT_CLASS = "piano__show-if-logged-out";
export const LOGGED_OUT_COMPONENT_SELECTOR = `.${LOGGED_OUT_CLASS}`;

// NOTE: This component is heavily controlled by Piano.jsx
// LOGGED_OUT_COMPONENT_SELECTOR is used as a selector by the Piano script to
// show and hide elements depending on whether the reader is logged in or not

const PianoShowIfLoggedOut = ({ children }) => {
  return (
    <li className={classNames(LOGGED_OUT_CLASS, "hidden list-none")}>
      {children}
    </li>
  );
};

export default PianoShowIfLoggedOut;
