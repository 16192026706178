import React from "react";
import { TitleSVGDesktop, TitleSVGMobile } from "./titleSVG/titleSVG";
import GraphSVGDesktop from "../404/components/graphSVGDesktop";
import GraphSVGMobile from "../404/components/graphSVGMobile";
import { datadogLogs } from "@datadog/browser-logs";

type ErrorBoundaryProps = {
  fullSizeError?: boolean;
  children: React.ReactElement;
};

type ErrorBoundaryState = {
  fullSizeError?: boolean;
  hasError: boolean;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, fullSizeError: this.props.fullSizeError };
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    datadogLogs.logger.error(`Client: ${error}`, errorInfo, error);
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <main>
            <div className="wrapper text-black-rock relative overflow-hidden">
              <div
                className={
                  'gap-y-none md:gap-y-2xl" mt-20 flex flex-col items-center gap-y-xl pb-4xl md:mt-32 lg:mx-auto lg:w-5/6 lg:pb-4xl'
                }
              >
                <div className={"mb-8 hidden w-100 max-w-[1032px] md:block"}>
                  <TitleSVGDesktop />
                </div>
                <div className={"mb-6 mt-10 w-100 max-w-[258px] md:hidden"}>
                  <TitleSVGMobile />
                </div>
                <GraphSVGDesktop
                  lastItemLabel={<>&nbsp;&nbsp;&nbsp;&nbsp;error</>}
                />
                <GraphSVGMobile
                  lastItemLabel={<>&nbsp;&nbsp;&nbsp;&nbsp;error</>}
                />
                <div className="mb-6 mt-4 w-100 max-w-[272px] text-[16px] leading-[24px] md:mt-0 md:max-w-[735px] md:text-[20px] md:leading-[30px] lg:max-w-[1100px] lg:text-[25px] lg:leading-[37.5px]">
                  <p className="font-bold">Ouch!</p>
                  <p>
                    There has been a client-side error and we don’t really know
                    what it is nor how to fix it. Our team gets informed
                    automatically when these things happens so we are likely
                    already trying to fix it!
                  </p>
                  <br />
                  In the meantime you can do two things:
                  <ol
                    aria-label={"How to fix a problem"}
                    className={"ml-9 list-decimal"}
                  >
                    <li>You can try to reload the page</li>
                    <li>
                      You can check out one of the{" "}
                      <a
                        className={"text-cerise"}
                        href={"/"}
                        aria-label={"Homepage"}
                      >
                        links
                      </a>{" "}
                      in the chart, or use the menu at the top of the page
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </main>
        </>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
