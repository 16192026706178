import { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";

import type { SubscriptionLevel } from "lib/types";

import useReaderSubscriptionLevel from "./useReaderSubscriptionLevel";
import { isMissingInfo, isToOnboard, isToVerify } from "lib/helpers/piano";

export type UserInfo = {
  pianoId: Promise<UserInfoResolved["pianoId"]>;
  readerSubscriptionLevel: SubscriptionLevel;
  hasMissingInfo: () => Promise<boolean>;
  showOnboarding: () => Promise<boolean>;
  showVerification: () => Promise<boolean>;
};

export type UserInfoResolved = {
  pianoId: string | null;
  readerSubscriptionLevel: SubscriptionLevel;
};

const useUserInfo = (): UserInfo => {
  const readerSubscriptionLevel = useReaderSubscriptionLevel();
  const [cookies] = useCookies();

  let resolvePromise: Function;
  const pianoIdPromise = useRef(
    new Promise<UserInfoResolved["pianoId"]>((resolve) => {
      resolvePromise = resolve;
    })
  );

  useEffect(() => {
    const executeAfterPianoInit = (fn: () => void) => {
      window.tp = window.tp || [];
      window.tp.push(["init", fn]);
    };

    executeAfterPianoInit(() => {
      const pianoIdUser = window.tp.pianoId.getUser();
      if (pianoIdUser) {
        return resolvePromise(pianoIdUser.uid);
      } else {
        return resolvePromise(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginCookie = cookies["__utp"];

  return {
    pianoId: pianoIdPromise.current,
    readerSubscriptionLevel,
    hasMissingInfo: () => isMissingInfo(loginCookie),
    showOnboarding: () => isToOnboard(loginCookie, readerSubscriptionLevel),
    showVerification: () => isToVerify(),
  };
};

export default useUserInfo;
